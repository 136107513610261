import React from 'react';
import QtySelector from '../QtySelector/QtySelector';
import Currency from '../../core/Currency/Currency';
import './ProductItem.scss';

export interface IProductItem {
    codart: string,
    desart: string,
    familia?: string,
    subfamilia?: string,
    price: number,
    imgUrl?: string,
    showPrice: boolean,
    unidad?: string,
    nota?: string
};

const ProductItem: React.FC<IProductItem> = (articulo: IProductItem) => {
    const { codart, desart, familia, subfamilia, price, imgUrl, showPrice, unidad, nota } = articulo;

    const renderPriceAndCart = () => {
        if (showPrice) {
            return (
                <>
                    <p className="Price"><Currency value={price} /></p>
                    <div className="QtyCell">
                        <QtySelector 
                            desart={desart}
                            codart={codart} 
                            price={price}
                            isCheckout={false}
                            addOnEnter={true}
                        />
                    </div>
                </>
            );
        }
    };

    return (
        <div className="col-lg-4 col-md-6 ">
            <div className="ProductItem">
                <div className="ImgContainer">
                    <img 
                        alt={desart}
                        className={!imgUrl ? 'notAvailable' : ''}
                        src={imgUrl ? `http://www.mcsiles.com.ar/usuarios/zalazarweb/${imgUrl}` : './assets/no-disponible.jpg'} 
                    />
                </div>
                <div className={`ContentContainer ${showPrice ? 'loggedProduct' : null}`}>
                    <p className="Codart">CÓD. {codart.toUpperCase()}</p>
                    <h3 className="Desart">{desart.toUpperCase()}</h3>
                    <p className="Categorias">{familia || '-'} {subfamilia ? `> ${subfamilia}` : null}</p>
                    {nota ? <p className="nota">{nota}</p> : null}
                    {unidad ? <p className="Unidad">x {unidad}</p> : null}
                    {renderPriceAndCart()}
                </div>
            </div>
        </div>
    );
};

export default ProductItem;