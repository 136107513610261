import React, { useState, useEffect, useCallback } from 'react';
import { sendContact, resetContactState } from './../../store/contact/contact.actions';
import Input from '../../core/Input/Input';
import Button from '../../core/Button/Button';
import Error from './../../components/Error/Error';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../core/Spinner/Spinner';
import './Contact.scss';
import { Helmet } from "react-helmet";

export interface IContactForm {
    name: string,
    tel?: string,
    email: string,
    message: string
};

const Contact: React.FC<{}> = React.memo(() => {
    const [ contactForm, setContactForm ] = useState<IContactForm>({ name: '', email: '', message: '' });
    const { error, fetching, msgSent } = useSelector((state: any) => state.contactStore);
    const dispatch = useDispatch();
    const resetContact = useCallback(() => { dispatch(resetContactState()); }, [dispatch]);

    useEffect(() => { resetContact(); }, [resetContact]);

    const onFormChange = (event: any = {}) => {
        setContactForm({
            ...contactForm,
            [event.target.id]: event.target.value
        });
    };

    const handleSubmit = (evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (contactForm.email === '' || contactForm.name === '' || contactForm.message === '') {
            return;
        } 

        dispatch(sendContact(contactForm));
    };

    const renderContact = () => {
        if (error) {
            return <Error retry={resetContact} />;
        }

        if (fetching) {
            return <Spinner>Enviando mensaje...</Spinner>;
        }

        if (msgSent) {
            return <p>Gracias por comunicarse con nosotros! Le responderemos a la brevedad.</p>
        }

        return (
            <form
                name="contactForm"
                onSubmit={handleSubmit}>
                <div className="form-group">
                    <Input 
                        id="name"
                        type="text"
                        className="form-control"
                        value={contactForm.name}
                        change={onFormChange} 
                        placeholder="Nombre" 
                        required={true} 
                    />
                </div>
                <div className="form-group">
                    <Input 
                        id="tel" 
                        type="tel" 
                        className="form-control"
                        value={contactForm.tel}
                        change={onFormChange} 
                        placeholder="Teléfono" 
                        required={false} 
                    />
                </div>
                <div className="form-group">
                    <Input 
                        id="email" 
                        type="email" 
                        className="form-control"
                        value={contactForm.email}
                        change={onFormChange} 
                        placeholder="Email" 
                        required={true} 
                    />
                </div>

                <div className="form-group MsgContainer">
                    <textarea 
                        id="message"
                        value={contactForm.message}
                        required
                        className="form-control" 
                        onChange={onFormChange}>
                    </textarea>
                </div>

                <div className="SendBtnContainer">
                    <Button type="submit">ENVIAR</Button>
                </div>
            </form>
        );
    };

    return (
        <div className="Contacto">
            <Helmet>
                <title>Zalazar Distribuidora | Contacto</title>
                <meta name="description" content="Zalazar Distribuidora  mayorista y minorista de productos " />
                <meta name="keywords" content="Zalazar, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
            </Helmet>
            <div className="container SectionContainer">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <h3 className="SectionTitle">Contacto</h3>
                        <p>Complete el siguiente formulario y nos comunicaremos <br /> con usted a la brevedad.</p>
                        <div className="separator"></div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="ContactoContent">
                            {renderContact()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Contact;