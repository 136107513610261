import React from 'react';
import { Helmet } from "react-helmet";
import Slider from './../../components/Slider/Slider';
import './Home.scss';
const Home: React.FunctionComponent<{}> = React.memo(() =>(
    <div>
        <Helmet>
            <title>Zalazar Distribuidora  | Tienda oficial</title>
            <meta name="description" content="Zalazar Distribuidora  mayorista y minorista de productos " />
            <meta name="keywords" content="Zalazar, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
        </Helmet>
        <Slider />
        <div className="Home container">
            <div className="row">
                <div className="col-md-6 PresentationContainer">
                    <p>Somos <b>Distribuidora Zalazar</b>, una empresa familiar de amplia trayectoria en <b>distribución de repuestos para sanitarios, gas, electrobombas, griferías, ferreteria y afines</b>.</p>
                    <p>Nuestros clientes y <b>más de 20 años de trayectoria</b> avalan nuestro compromiso por consolidar un mercado siempre en expansión, incorporando nuevas tecnologías en materia de distribución, logística y servicios para llegar a todos los sectores del país.</p>
                    <p>Nuestros principios están basados en una estrecha relación de <i>confianza, trabajo y profesionalismo de nuestros recursos humanos</i> para con nuestros clientes y proveedores, logrando un stock permanente, un extenso catálogo y asesoramiento profesional.</p>
                </div>
                <div className="col-md-6">
                    <div className="HighlightSection">
                        <span className="HomeIcon qualityIcon"></span>
                        <p>Compromiso de excelencia en la calidad</p>
                    </div>
                    <div className="HighlightSection">
                        <span className="HomeIcon stockIcon"></span>
                        <p>Stock Permanente de nuestro catálogo</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
));

export default Home;