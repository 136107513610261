import React from 'react';
import Logo from './../Logo/Logo';
import NavList, { NavListItemMap } from '../NavList/NavList';
import './CommonNav.scss';

const CommonNav: React.FunctionComponent<{}> = React.memo(() => {
    const navItems: NavListItemMap[] = [
        { activeClassName: 'active-route', className: '', exact: true, label: 'Home', to: '/' },
        { activeClassName: 'active-route', className: '', exact: true, label: 'Productos', to: '/productos' },
        { activeClassName: 'active-route', className: '', exact: true, label: 'Catálogo', to: 'https://online.fliphtml5.com/lgxay/besi/#p=1', external: true },
        { activeClassName: 'active-route', className: '', exact: true, label: 'Contacto', to: '/contacto' }
    ];

    return (
        <div className="container CommonNavContainer">
            <Logo className="LogoContainer" />

            <div className="NavContainer">
                <NavList items={navItems} className="CommonNavLinks" />
            </div>
        </div>
    );
});

export default CommonNav;