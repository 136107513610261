import React from 'react';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import Form, { FormElementMap } from '../../components/Form/Form';
import Error from './../../components/Error/Error';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../store/auth/auth.actions';
import Spinner from '../../core/Spinner/Spinner';

const Reset: React.FC<any> = React.memo(({ location }) => {
    const dispatch = useDispatch();
    const { fetching, fetchError, user, err } = useSelector((state: any) => state.authStore);
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });
    
    if (!token || user) {
        return <Redirect to='/' />
    }

    const onSubmit = (credentials: any = {}) => {
        if (credentials.PASSWORD === credentials.PASSWORD_REPEAT) {
            dispatch(resetPassword(token, credentials['PASSWORD']));
            return;
        }

        alert('La contraseña debe coincidir');
    };

    const formElements: FormElementMap[] = [{
        elementType: 'input',
        id: 'PASSWORD',
        placeholder: 'Contraseña',
        valueType: 'password',
        required: true,
        minLength: 6
    }, {
        elementType: 'input',
        id: 'PASSWORD_REPEAT',
        placeholder: 'Repita la contraseña',
        valueType: 'password',
        required: true,
        minLength: 6
    }];

    const renderForm = () => {
        if (fetchError) {
            alert(err)
            return <Error />;
        }

        if (fetching) {
            return <Spinner>Validando datos...</Spinner>;
        }

        return (
            <Form 
                onSubmit={onSubmit}
                elements={formElements}
                name="authForm"
                submitText="Aplicar cambio e ingresar"
            />
        );
    };

    return (
        <div className="container">
            <h3 className="SectionTitle">Reiniciar mi contraseña</h3>
            <div className="AuthFormContainer">{renderForm()}</div>
        </div>
    );
});

export default Reset;