import React from 'react';
import './Slider.scss';

const Slider: React.FunctionComponent<{}> = React.memo(() => (
    <div>
        <div 
            id="magSliderControls" 
            className="Slider carousel slide" 
            data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img 
                        className="d-block w-100" 
                        src="./assets/slider/2.jpg" 
                        alt="Zalazar"
                    />
                    <div className="container">
                        <div className="carousel-caption d-md-block SliderCaption">
                            <h1>Instalaciones y repuestos sanitarios</h1>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img 
                        className="d-block w-100" 
                        src="./assets/slider/1.jpg" 
                        alt="Zalazar"
                    />
                    <div className="container">
                        <div className="carousel-caption d-md-block SliderCaption">
                            <h1>Entregas en todo el país</h1>
                        </div>
                    </div>
                </div>
            </div>
    
            <a 
                className="carousel-control-prev" 
                href="#magSliderControls" 
                role="button" 
                data-slide="prev">
                <span 
                    className="carousel-control-prev-icon" 
                    aria-hidden="true">
                </span>
                <span className="sr-only">Anterior</span>
            </a>
            <a 
                className="carousel-control-next" 
                href="#magSliderControls" 
                role="button" 
                data-slide="next">
                <span 
                    className="carousel-control-next-icon" 
                    aria-hidden="true">
                </span>
                <span className="sr-only">Siguiente</span>
            </a>
        </div>
    </div>
));

export default Slider;