import React, { useEffect, useCallback, useRef } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux'
import * as familiasActions from '../../store/familias/familias.actions';
import FamilyNav from './../../components/FamiliaNav/FamiliaNav';
import Error from './../../components/Error/Error';
import ProductList from './../../components/ProductList/ProductList';
import Spinner from './../../core/Spinner/Spinner';
import Search from '../../components/Search/Search';
import qs from 'qs';
import './Products.scss';
import { Familia } from '../../classes/familia';
import Button from '../../core/Button/Button';
import Cliente from '../../classes/cliente';

const Products: React.FunctionComponent<any> = ({ match, location }) => {
    const { filter, familia, subfamilia } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { error, familias, fetching } = useSelector((state: any) => state.familiasStore);
    const cliente: Cliente = useSelector((state: any) => state.authStore.user);
    const dispatch = useDispatch();

    const fetchData = useCallback(() => {
        if (!familias.length) dispatch(familiasActions.requestFamilias());
    }, [dispatch, familias.length]);

    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef)

    useEffect(() => {
        fetchData();
        const f: Familia = familias.find((f: Familia) => f.ID_FLIA === Number(familia));

        const hasNoSubfamilia = f && f.SUBFAMILIAS.length === 0;

        if (filter || hasNoSubfamilia || subfamilia) {
            executeScroll();
        }
        // eslint-disable-next-line
    }, [fetchData, filter, familia, subfamilia]);

    if (error) {
        setTimeout(fetchData, 2000);
        return (
            <div className="container ProductsContainer">
                <Spinner>Buscando categorías...</Spinner>
            </div>
        );
    }

    if (fetching) {
        return (
            <div className="container ProductsContainer">
                <Spinner>Buscando productos y categorías...</Spinner>
            </div>
        );
    }

    const openXLS = () => {
        window.location.href = 'http://www.mcsiles.com.ar/usuarios/zalazarweb/ARTICULO.XLS';
    };

    const openPDF = () => {
        window.location.href = 'http://www.mcsiles.com.ar/usuarios/zalazarweb/ARTICULO.PDF';
    };

    const renderButtons = () => {
        if (cliente === null) return;

        return (
            <div style={{height: '100px'}}>
                <Button click={() => openXLS()} className="UltimaModificacion">Descargar lista de precios (Excel)</Button>
                <Button click={() => openPDF()} className="UltimaModificacion">Descargar lista de precios (PDF)</Button>
            </div>
        );
    }

    return (
        <div className="container">
            <Helmet>
                <title>Zalazar Distribuidora | Productos</title>
                <meta name="description" content="Zalazar Distribuidora  mayorista y minorista de productos " />
                <meta name="keywords" content="Zalazar, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
            </Helmet>

            {renderButtons()}

            
            <div className="container ProductsContainer">
                <div className="ProductFilterContainer">
                    <Search />
                </div>
                <div className="row">
                    <div className="FamiliasContainer">
                        <div className="FamiliaList">
                            <FamilyNav familias={familias} match={match} />
                        </div>
                    </div>
                    <div className="ProductListContainer" ref={myRef}>
                        <Route path={`${match.path}/`} component={ProductList}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;