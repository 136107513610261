import React from 'react';
import './Footer.scss';

const Footer: React.FunctionComponent<{}> = React.memo(() => {
    return (
        <footer className="FooterContainer">
            <div className="MainFooterContainer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                <a href="mailto:zalazardistribuidora@gmail.com"><b>zalazardistribuidora</b>@gmail.com</a>
                            </p>
                            <p className="Number">Teléfono y Fax: <b>+54 11 4466-2705</b></p>
                            <p className="Number">Teléfono: <b>+54 11 6088-9155</b></p>
                            <p className="Number">
                                <a href="https://wa.me/541136945737"><span className="SmallIcon WpIcon"></span> 11 3694-5737</a>
                            </p>
                            <p className="Number">
                                <a href="https://www.instagram.com/distribuidorazalazar/" target="_blank" rel="noopener noreferrer" >
                                    <span className="SmallIcon InstIcon"></span> <span className="insta">@zalazardistribuidora</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="SubfooterContainer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">Zalazar Distribuidora  | 2020</div>
                        <div className="col-md-6 col-sm-6 PowerBy">
                            <a 
                                href="http://huskysoftware.com.ar" 
                                target="_blank"
                                rel="noopener noreferrer">Desarrollo <span className="husky-logo"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
});

export default Footer;
