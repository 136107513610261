import { updateState } from './../helpers';
import { 
    FETCH_ARTICULOS, 
    FETCH_ARTICULOS_ERROR,
    FETCH_ARTICULOS_SUCCESS
} from './articulos.actions';

const initialState = { 
    articulos: [],
    error: false,
    fetching: false,
    totalPages: 0,
    currentPage: 0,
};

export default (state = initialState, action: any = {}) => {
    switch (action.type) {
        case FETCH_ARTICULOS: return updateState(state, { error: false, fetching: true });
        case FETCH_ARTICULOS_SUCCESS: return updateState(state, { articulos: action.data.articulos, error: false, fetching: false, totalPages: action.data.totalPages, currentPage: action.data.currentPage });
        case FETCH_ARTICULOS_ERROR: return updateState(state, { error: true, fetching: false });
        default: return state;
    };
};
