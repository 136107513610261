import axios from 'axios';
import constants from './../../constants';
import Articulo from '../../classes/articulo';
import { Dispatch } from 'react';
export const FETCH_ARTICULOS = 'FETCH_ARTICULOS';
export const FETCH_ARTICULOS_SUCCESS = 'FETCH_ARTICULOS_SUCCESS';
export const FETCH_ARTICULOS_ERROR = 'FETCH_ARTICULOS_ERROR';

const fetchArticulos = () => ({ type: FETCH_ARTICULOS });
const fetchArticulosError = () => ({ type: FETCH_ARTICULOS_ERROR });
const fetchArticulosSuccess = (data: any) => ({ data, type: FETCH_ARTICULOS_SUCCESS });

export const requestArticulos = ({ 
    familia = 0, 
    subfamilia = 0, 
    filter = '',
    page = 1,
} = {}) => (dispatch: Dispatch<any>) => dispatch(getArticulos({ familia, subfamilia, filter, page }));


const getArticulos = ({ familia = 0, subfamilia = 0, filter = '', page = 1 } = {}) => (dispatch: Dispatch<any>) => {
    dispatch(fetchArticulos());

    let queryParams: string;

    if (filter) {
        queryParams = `filter=${filter}`;
    } else {
        queryParams = `familia=${familia}`
        queryParams += subfamilia ? `&subfamilia=${subfamilia}` : '';
    }

    return axios.get(`${constants.server}/articulos?${queryParams}&page=${page}&limit=30`)
        .then((response: any) => dispatch(fetchArticulosSuccess(response.data)))
        .catch(() => dispatch(fetchArticulosError()));
};
