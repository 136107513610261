import React, { useEffect, useCallback } from 'react';
import * as authActions from '../../store/auth/auth.actions';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { resetContactState } from './../../store/contact/contact.actions';
import Error from './../../components/Error/Error';
import Form, { FormElementMap } from './../../components/Form/Form';
import Spinner from './../../core/Spinner/Spinner';
import { Helmet } from "react-helmet";
const Auth: React.FunctionComponent<any> = ({ match = {}}) => {
    const dispatch = useDispatch();
    const state = useSelector((state: any) => state.authStore);
    const login: boolean = match.path === '/ingresar';
    const resetAuthState = useCallback(() => { dispatch(authActions.resetAuthState()); dispatch(resetContactState()); }, [dispatch]);

    const onSubmit = (credentials: any) => dispatch(authActions.authenticate({ credentials, loginAction: login }));

    useEffect(() => { resetAuthState(); }, [resetAuthState, login]);

    const renderForm = () => {
        const formElements: FormElementMap[] = [{ 
            elementType: 'input', 
            id: 'E_MAIL', 
            placeholder: 'Correo Electrónico', 
            valueType: 'email', 
            required: true, 
            minLength: 8 
        }];

        if (!login) {
            formElements.push({ 
                elementType: 'input', 
                id: 'CUITCLI', 
                placeholder: 'CUIT con guiones (-)', 
                valueType: 'text', 
                required: true, 
                minLength: 13,
                maxLength: 13 
            });
        }

        formElements.push({
            elementType: 'input',
            id: 'PASSWORD',
            placeholder: 'Contraseña',
            valueType: 'password',
            required: true,
            minLength: 6
        });

        return (
            <>
                <Form 
                    onSubmit={onSubmit}
                    elements={formElements}
                    name="authForm"
                    submitText={login ? 'Ingresar' : 'Registrarme'}
                />
                <p><Link to='/olvide-mi-contrasena'>Olvidó su contraseña?</Link></p>
            </>
        );
    };

    const renderAuthView = () => {
        const { fetchError, fetching, invalidReason } = state;

        if (fetching) {
            return <Spinner>Validando credenciales...</Spinner>;
        }

        if (fetchError) {
            return <Error retry={resetAuthState} />;
        }

        return (
            <div className="AuthFormContainer">
                {renderForm()}
                {invalidReason !== '' ? <p className="ErrorMsg">{invalidReason}</p> : null}
            </div>
        );
    };

    if (state.user !== null) {
        return <Redirect to="/productos" />
    }
  
    return (
        <div className="container SectionContainer">
            <Helmet>
                <title>Zalazar Distribuidora | Ingreso de usuarios</title>
                <meta name="description" content="Zalazar Distribuidora  mayorista y minorista de productos " />
                <meta name="keywords" content="Zalazar, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
            </Helmet>
            <h3 className="SectionTitle">{login ? 'Ingresar' : 'Registrarme'}</h3>
            {renderAuthView()}
        </div>
    );
};

export default Auth;